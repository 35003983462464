import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { URL_AXIOS } from '../../URL/URL';
import axios from 'axios';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import CropperImage from '../Cropper';
import { ThemeContext } from '../../context/ContextHeader';
import { useContext } from 'react';
function MenuCentre(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState('top');
  const {
    dossiers,
    dossiersCorbeille,
    DossiersRecu,
    DossiersArchives,
    visibleEntete, setVisibleEntete,
    boolResultat, setBoolResult,
    boolNewFolder, setBoolNewFolder,
    boolSendFolder, setBoolSendFolder,
    boolArchives, setBoolArchives,
    boolStatistique, setBoolStatistique,
    boolCorbeille, setBoolCorbeille,
    boolNotification, setBoolNotification,
    boolAgenda, setBoolAgenda,
    jourRestant
  } = useContext(ThemeContext);

  // const ColorMenu=()=>{
  //   alert("Bonjour "+location.pathname)
  //   if (location.pathname = "/resultat") {
  //     setBoolResult(true);
  //     setBoolArchives(false);
  //     setBoolCorbeille(false);
  //     setBoolNewFolder(false);
  //     setBoolSendFolder(false);
  //     setBoolStatistique(false);
  //   } else if (location.pathname = "/createFolder") {
  //     setBoolResult(false);
  //     setBoolArchives(false);
  //     setBoolCorbeille(false);
  //     setBoolNewFolder(true);
  //     setBoolSendFolder(false);
  //     setBoolStatistique(false);
  //   } else if (location.pathname = "/archives") {
  //     setBoolResult(false);
  //     setBoolArchives(true);
  //     setBoolCorbeille(false);
  //     setBoolNewFolder(false);
  //     setBoolSendFolder(false);
  //     setBoolStatistique(false);
  //   } else if (location.pathname = "/resultat_transfere") {
  //     setBoolResult(false);
  //     setBoolArchives(false);
  //     setBoolCorbeille(false);
  //     setBoolNewFolder(false);
  //     setBoolSendFolder(true);
  //     setBoolStatistique(false);
  //   } else if (location.pathname = "/statistiques") {
  //     setBoolResult(false);
  //     setBoolArchives(false);
  //     setBoolCorbeille(false);
  //     setBoolNewFolder(false);
  //     setBoolSendFolder(false);
  //     setBoolStatistique(true);
  //   } else if (location.pathname = "/corbeille") {
  //     setBoolResult(false);
  //     setBoolArchives(false);
  //     setBoolCorbeille(true);
  //     setBoolNewFolder(false);
  //     setBoolSendFolder(false);
  //     setBoolStatistique(false);
  //   }
  // }
  useEffect(() => {
if(jourRestant>0){
    if (location.pathname === "/resultat") {
      setBoolResult(true);
      setBoolArchives(false);
      setBoolCorbeille(false);
      setBoolNewFolder(false);
      setBoolSendFolder(false);
      setBoolStatistique(false);
      setBoolAgenda(false);
      setBoolNotification(false);
    } else if (location.pathname === "/createFolder") {
      setBoolResult(false);
      setBoolArchives(false);
      setBoolCorbeille(false);
      setBoolNewFolder(true);
      setBoolSendFolder(false);
      setBoolStatistique(false);
      setBoolAgenda(false);
      setBoolNotification(false);
    } else if (location.pathname === "/archives") {
      setBoolResult(false);
      setBoolArchives(true);
      setBoolCorbeille(false);
      setBoolNewFolder(false);
      setBoolSendFolder(false);
      setBoolStatistique(false);
      setBoolAgenda(false);
      setBoolNotification(false);
    } else if (location.pathname === "/resultat_transfere") {
      setBoolResult(false);
      setBoolArchives(false);
      setBoolCorbeille(false);
      setBoolNewFolder(false);
      setBoolSendFolder(true);
      setBoolStatistique(false);
      setBoolAgenda(false);
      setBoolNotification(false);
    } else if (location.pathname === "/statistiques") {
      setBoolResult(false);
      setBoolArchives(false);
      setBoolCorbeille(false);
      setBoolNewFolder(false);
      setBoolSendFolder(false);
      setBoolStatistique(true);
      setBoolAgenda(false);
      setBoolNotification(false);
    } else if (location.pathname === "/corbeille") {
      setBoolResult(false);
      setBoolArchives(false);
      setBoolCorbeille(true);
      setBoolNewFolder(false);
      setBoolSendFolder(false);
      setBoolStatistique(false);
      setBoolAgenda(false);
      setBoolNotification(false);
    }else if (location.pathname === "/agenda") {
      setBoolAgenda(true);
      setBoolNotification(false);
      setBoolResult(false);
      setBoolArchives(false);
      setBoolCorbeille(false);
      setBoolNewFolder(false);
      setBoolSendFolder(false);
      setBoolStatistique(false);

    }else if (location.pathname === "/notifications") {
      setBoolAgenda(false);
      setBoolNotification(true);
      setBoolResult(false);
      setBoolArchives(false);
      setBoolCorbeille(false);
      setBoolNewFolder(false);
      setBoolSendFolder(false);
      setBoolStatistique(false);

    }
  
  }else{
    navigate('/resultat')
  }
  }, [location.pathname,jourRestant])
  return (
    <ul class="sidebar-menu" id="nav-accordion">
      <Dialog header="Veuillez joindre une entête" visible={visibleEntete} position={position} style={{ width: '60vw' }} onHide={() => setVisibleEntete(false)} draggable={false} resizable={false}>
        <div className='container-fluid'>
          <div className='row'>
            <CropperImage />
          </div>
        </div>

      </Dialog>
      <p className="centered ml-[20px]">
        <a href="profile.html"><img src="../../../logo.png" class="img-round" width={80} height={80} /></a>
      </p>
      <h6 class="centered" style={{ whiteSpace: 'nowrap', color: 'white' }}>{props.username}</h6>
      <hr style={{ width: "90%" }} />
      <li class="sub-menu" style={boolResultat == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
        <a href="javascript:;" onClick={() => navigate('/resultat')} style={boolResultat == true ? { color: 'white' } : {}}>
          <i class="fa fa-desktop"></i>
          <span>Résultats&nbsp;&nbsp;<span class="badge bg-theme">{DossiersRecu.length}</span></span>
        </a>
      </li>
      <li className={jourRestant>0 ? "sub-menu" :"sub-menu disabledLi"} style={boolNewFolder == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>

        <a href="javascript:;" onClick={() => navigate('/createFolder')} style={boolNewFolder == true ? { color: 'white' } : {}}>

          <i class="fa fa-folder"></i>
          <span>Nouveau dossier</span>

        </a>

      </li>
      <li className={jourRestant>0 ? "sub-menu" :"sub-menu disabledLi"} style={boolSendFolder == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
        <a href="javascript:;" onClick={() => navigate('/resultat_transfere')} style={boolSendFolder ? { color: 'white' } : {}}>
          <i class="fa fa-upload"></i>
          <span>Dossiers transférés&nbsp;&nbsp;<span class="badge bg-theme">{dossiers.length}</span></span>
        </a>
      </li>
      <li className={jourRestant>0 ? "sub-menu" :"sub-menu disabledLi"} style={boolArchives == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
        <a href="javascript:;" onClick={() => navigate('/archives')} style={boolArchives == true ? { color: 'white' } : {}}>
          <i class="fa fa-archive"></i>
          <span>Archives&nbsp;&nbsp;<span class="badge bg-theme">{DossiersArchives.length}</span></span>
        </a>
      </li>
      <li className={jourRestant>0 ? "sub-menu" :"sub-menu disabledLi"} style={boolNotification == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
        <a href="javascript:;" onClick={() => navigate('/notifications')} style={boolNotification == true ? { color: 'white' } : {}}>
          <i class="fa fa-bell-o"></i>
          <span>Notifications</span>
        </a>
      </li>
      <li className={jourRestant>0 ? "sub-menu" :"sub-menu disabledLi"}>
        <a href="javascript:;" onClick={() => setVisibleEntete(true)}>
          <i class="fa fa-picture-o"></i>
          <span>Entête</span>
        </a>
      </li>
      <li className={jourRestant>0 ? "sub-menu" :"sub-menu disabledLi"} style={boolAgenda == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
        <a href="javascript:;" onClick={() => navigate('/agenda')} style={boolAgenda == true ? { color: 'white' } : {}}>
          <i class="fa fa-book"></i>
          <span>Agenda</span>
        </a>
      </li>
      <li className={jourRestant>0 ? "sub-menu" :"sub-menu disabledLi"} style={boolStatistique == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
        <a href="javascript:;" onClick={() => navigate('/statistiques')} style={boolStatistique == true ? { color: 'white' } : {}}>
          <i class="fa fa-bar-chart-o"></i>
          <span>Statistiques</span>
        </a>
      </li>
      <li className={jourRestant>0 ? "sub-menu" :"sub-menu disabledLi"} style={boolCorbeille == true ? { backgroundColor: '#4ECDC4', borderRadius: "100px", color: 'white !important' } : {}}>
        <a href="javascript:;" onClick={() => navigate('/corbeille')} style={boolCorbeille == true ? { color: 'white' } : {}}>
          <i class="fa fa-bitbucket"></i>
          <span>Corbeille&nbsp;&nbsp;<span class="badge " style={{ backgroundColor: 'red' }}>{dossiersCorbeille.length}</span></span>
        </a>
      </li>
    </ul>
  );
}

export default MenuCentre;