import React, { useState, useRef, useCallback, useEffect, useContext } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import Footer from '../Footer';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import CustomWebcam from './CustomWebcam';
import Webcam from "react-webcam";
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from "primereact/checkbox";
import { URL_AXIOS } from '../../URL/URL';
import { Dialog } from 'primereact/dialog';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Link, useNavigate } from 'react-router-dom';
import { Message } from 'primereact/message';
//import { MultiSelect } from 'react-multi-select-component';
import { MultiSelect } from 'primereact/multiselect';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { ThemeContext } from '../../context/ContextHeader';
import { MdAttachment } from "react-icons/md";
import { Divider } from 'primereact/divider';
import Loading from './Loading';
import WhatssapSmsOne from './WhatssapSmsOne';
import WhatssapSmsTwo from './WhatssapSmsTwo';
import ReactDOMServer from 'react-dom/server';
import { AutoComplete } from 'primereact/autocomplete';

function CreateFolder() {
    const navigate = useNavigate();
    const { statusEnvoiFolder, setStatusEnvoiFolder, setDossiers, ville } = useContext(ThemeContext);
    const [selectedSexe, setSelectedSexe] = useState(null);
    const [examens, setExamens] = useState([]);
    const [examensTwo, setExamensTwo] = useState([]);
    const [examensThree, setExamensThree] = useState([]);
    const [examensFour, setExamensFour] = useState([]);
    const [urgence, setUrgence] = useState('');
    const [ingredient, setIngredient] = useState('');
    const [value, setValue] = useState('');
    const [items, setItems] = useState([]);
    const toast = useRef(null);
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef(null);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [inc, setInc] = useState(0);
    const [increment, setIncrement] = useState([inc]);
    const [webcamCaptures, setWebcamCaptures] = useState([]);
    const [visible, setVisible] = useState(false);
    const [visibleMessage, setVisibleMessage] = useState(false);
    const [visibleAgenda, setVisibleAgenda] = useState(false);
    const [visibleCapture, setVisibleCapture] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [siteid, setSiteid] = useState(1);
    const [membreid, setMembreid] = useState(1);
    const [saveStatus, setSaveStatus] = useState(false);
    const [siteMembre, setSiteMembre] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState([]);
    const [medecinradio, setMedecinradio] = useState('');
    const [message_nomcomplet, setMessage_nomcomplet] = useState("");
    const [message_examen, setMessage_examen] = useState("");
    const [message_prescripteur, setMessage_prescripteur] = useState("");
    const [message_bulettin, setMessage_bulettin] = useState("");
    const [message_upload, setMessage_upload] = useState("");
    const [message_medecin, setMessage_medecin] = useState("");
    const [checkFields, setCheckFields] = useState(true);
    const [selected, setSelected] = useState([]);
    const [membres, setMembres] = useState([]);
    const [dataGet, setDataGet] = useState([]);
    const [checkStatusOfFields, setCheckStatusOfFields] = useState(false);
    const [visibleFile, setVisibleFile] = useState(false);
    const useRefInputImage = useRef(null);
    const usernameconnected = sessionStorage.getItem("nom");
    const user_id = sessionStorage.getItem("user_id");
    const site_id = parseInt(sessionStorage.getItem("site_id"));
    const membre_id = sessionStorage.getItem("membre_id");
    const [visibleExamen, setVisibleExamen] = useState(false);
    const fileInputRef = useRef(null);
    const [visibleProgressBar, setVisibleProgressBar] = useState(false);
    const [valueProgressBar, setValueProgressBar] = useState(0);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [agenda, setAgenda] = useState([]);
    const [gains, setGains] = useState([]);
    const [societe, setSociete] = useState('');
    const [selectedMessage, setSelectedMessage] = useState("");
    const [boolIncr, setBoolIncr] = useState(false);
    const [selectedCentreDeSoin, setSelectedCentreDeSoin] = useState(null);
    const escapeHtml = (string) => {
        const div = document.createElement('div');
        div.innerHTML = string;
        return div.textContent || div.innerText || '';
    };
    const initialMessageOne = escapeHtml(ReactDOMServer.renderToString(<WhatssapSmsOne societe={societe} />));
    const initialMessageTwo = escapeHtml(ReactDOMServer.renderToString(<WhatssapSmsTwo societe={societe} />));
    const [message_one, setMessage_one] = useState(initialMessageOne);
    const [message_two, setMessage_two] = useState(initialMessageTwo);
    const [centressoins, setCentressoins] = useState([]);
    const [message_three, setMessage_three] = useState(" Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux 3")
    const [ingredientMsgOne, setIngredientMsgOne] = useState('');
    const [ingredientMsgTwo, setIngredientMsgTwo] = useState('');
    const [ingredientMsgThree, setIngredientMsgThree] = useState('');
    const [numberDoc, setNumberDoc] = useState("");
    const [modalite, setModalite] = useState([]);
    const [loading, setLoading] = useState(true);

    const [filteredCountries, setFilteredCountries] = useState(null);

    const search = (event) => {
        // Timeout to emulate a network connection
        setTimeout(() => {
            let _filteredCountries;

            if (!event.query.trim().length) {
                _filteredCountries = [...centressoins];
            }
            else {
                _filteredCountries = centressoins.filter((country) => {
                    return country.societe.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredCountries(_filteredCountries);
        }, 250);
    }


    const liste_site_contrat_get_numberMedecin = async (id, contratid) => {
        try {
            const response = await axios.get(URL_AXIOS + `get_sites_membres_by_site_id_and_medecin/${id}/${contratid}`);

            if (response.data.status == 200) {
                setSociete(response.data.vue_sites_membres.societe);
                setNumberDoc(response.data.vue_sites_membres.telephone_membres);
            }
        } catch (Error) {

        }
    }
    const liste_modalite = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'list_modalite');
            if (response.data.status === 200) {
                setModalite(response.data.modalites);
            }
        } catch (Error) {

        }
    }
    const liste_centre_des_soins = async (city) => {
        try {
            const response = await axios.get(URL_AXIOS + `centresByVille/${city}`);
            if (response.data.status == 200) {
                setCentressoins(response.data.sites);
               
            }
        } catch (Error) {

        }

    }
    const fetchMembres = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `ListeManipulateurBySite/${site_id}`);
            if (response.data.status === 200) {

                const mappedMembres = response.data.vue_manipulateurs_site.map((item) => ({
                    label: item.nomcomplet,
                    value: item.id_mani_site
                }));
                setMembres(mappedMembres);
            }
        } catch (error) {
            console.error("Error fetching membres:", error);
        }
    };
    const openFolderPickFileImage = (i) => {
        const onChangeVal = [...dataDiv];
        onChangeVal[i].imgScr_cap = "";
        setDataDiv(onChangeVal);
        useRefInputImage.current.click();
    }
    const [dataDiv, setDataDiv] = useState([
        {
            nom_patient: '',
            sexe: '',
            libelle_age: 'an(s)',
            observation: '',
            categorie_fichier: '',
            prescripteur: '',
            etat: 0,
            imgSrc: '',
            imgScr_cap: '',
            uploadedFile: [],
            bool: false,
            bool_obs: false,
            age: '',
            message_age: '',
            bool_msg_age: false,
            message_sexe: '',
            bool_msg_sexe: false,
            message_nomcomplet: '',
            bool_msg_nomcomplet: false,
            message_categorie_fichier: '',
            bool_msg_categorie_fichier: false,
            message_prescripteur: '',
            bool_msg_prescripteur: false,
            message_imgSrc: '',
            bool_msg_imgSrc: false,
            message_uploadedFile: '',
            bool_msg_uploadedFile: false,
            totalSize: 0,
            selected: [],
            message_mani: '',
            bool_mani: false,
            filehelp: '',
            bool_examen: false,
            examen_modalite: '',
            examen_one: '',
            examen_two: '',
            examen_three: '',
            examen_four: '',
            examen_one_bool: true,
            examen_two_bool: true,
            examen_three_bool: true,
            examen_four_bool: true,
            categorie_fichier_one: '',
            message_error: "",
            site_id: site_id,
            tabExamen: [],
            categorie_fichier_save: '',
            bool_large: false,
            msg_examen: "",
            selectedCentreDeSoin: null,
            message_selectedCentreDeSoin: '',
            bool_msg_selectedCentreDeSoin: false,
        }
    ]);



    const handleIncrement = () => {
        setDataDiv([...dataDiv, {
            nom_patient: '',
            sexe: '',
            libelle_age: '',
            observation: '',
            categorie_fichier: '',
            prescripteur: '',
            etat: 0,
            imgSrc: '',
            imgScr_cap: '',
            uploadedFile: [],
            bool: false,
            bool_obs: false,
            age: '',
            message_age: '',
            bool_msg_age: false,
            message_sexe: '',
            bool_msg_sexe: false,
            message_nomcomplet: '',
            bool_msg_nomcomplet: false,
            message_categorie_fichier: '',
            bool_msg_categorie_fichier: false,
            message_prescripteur: '',
            bool_msg_prescripteur: false,
            message_imgSrc: '',
            bool_msg_imgSrc: false,
            message_uploadedFile: '',
            bool_msg_uploadedFile: false,
            totalSize: 0,
            selected: [],
            message_mani: '',
            bool_mani: false,
            filehelp: '',
            bool_examen: false,
            examen_modalite: '',
            examen_one: '',
            examen_two: '',
            examen_three: '',
            examen_four: '',
            examen_one_bool: false,
            examen_two_bool: false,
            examen_three_bool: true,
            examen_four_bool: true,
            categorie_fichier_one: '',
            message_error: "",
            site_id: site_id,
            tabExamen: [],
            categorie_fichier_save: '',
            bool_large: false,
            msg_examen: "",
            selectedCentreDeSoin: null,
            message_selectedCentreDeSoin: '',
            bool_msg_selectedCentreDeSoin: false,
        }]);


    }

    const captureindex = (i) => {
        try {
            const imageSrc = webcamRef.current.getScreenshot();
            const newDataDiv = [...dataDiv];
            newDataDiv[i].imgSrc = imageSrc;
            newDataDiv[i].bool_large = false;
            newDataDiv[i].imgScr_cap = imageSrc;
            if (newDataDiv[i].imgSrc.length === 0) {
                newDataDiv[i].message_imgSrc = "Veuillez capturer une photo ";
                newDataDiv[i].bool_msg_imgSrc = true;
            } else if (newDataDiv[i].imgSrc.length > 0) {
                newDataDiv[i].message_imgSrc = '';
                newDataDiv[i].bool_msg_imgSrc = false;
            }
            setDataDiv(newDataDiv);
        } catch (error) {

        }

    };
    const deleteDiv = (i) => {
        try {
            const deletedata_div = [...dataDiv];
            deletedata_div.splice(i, 1);
            setDataDiv(deletedata_div);
        } catch (error) {

        }

    }

    const handleChange = (e, i) => {
        const { name, value } = e.target;
        const onChangeVal = [...dataDiv];
        onChangeVal[i][name] = value;
        setDataDiv(onChangeVal);
    }

    const handleChangeSelectCentreSoin = (value, i) => {

        const onChangeVal = [...dataDiv];
        if (value) {
            onChangeVal[i].selectedCentreDeSoin = value;
            onChangeVal[i].message_selectedCentreDeSoin = "";
        }

        setDataDiv(onChangeVal);
    }

    const handleChangeAge = (e, i) => {
        const { name, value } = e.target;
        const onChangeVal = [...dataDiv];
        onChangeVal[i][name] = value;
        onChangeVal[i].message_age = '';
        onChangeVal[i].bool_msg_age = false;
        setDataDiv(onChangeVal);
    }

    const handleChangeSexe = (e, i) => {
        const { name, value } = e.target;
        const onChangeVal = [...dataDiv];
        onChangeVal[i].sexe = value;
        onChangeVal[i].message_sexe = '';
        onChangeVal[i].bool_msg_sexe = false;
        setDataDiv(onChangeVal);
    }

    const handleModalite = (e, i) => {
        const { name, value } = e.target;
        const onChangeVal = [...dataDiv];
        onChangeVal[i].modalite = value;
        onChangeVal[i].examen_one_bool = false;
        setDataDiv(onChangeVal);
    }
    const handleExamen_one = (e, i) => {
        const { name, value } = e.target;
        const onChangeVal = [...dataDiv];
        onChangeVal[i].examen_one = value;
        onChangeVal[i].examen_two_bool = false;
        setDataDiv(onChangeVal);
    }

    const handleExamen_two = (e, i) => {
        const { name, value } = e.target;
        const onChangeVal = [...dataDiv];
        onChangeVal[i].examen_two = value;
        onChangeVal[i].examen_three_bool = false;
        setDataDiv(onChangeVal);
    }

    const onSelectExamenTwo = async (e, i) => {
        const onChangeVal = [...dataDiv];
        const selectedExamenOne = onChangeVal[i].examen_one;

        try {

            const filteredData = examens.filter(item => {
                return !item.libelle_exam.includes(selectedExamenOne)
            });
            setExamensTwo(filteredData);
            onChangeVal[i].examen_three_bool = false;
            setDataDiv(onChangeVal);

        } catch (Error) {

        }

    }

    const onSelectExamenThree = async (e, i) => {
        const onChangeVal = [...dataDiv];
        const selectedExamenOne = onChangeVal[i].examen_one;
        const selectedExamentwo = onChangeVal[i].examen_two;
        try {
            const filteredData = examens.filter(item =>
                !item.libelle_exam.includes(selectedExamenOne) && !item.libelle_exam.includes(selectedExamentwo)
            );
            setExamensThree(filteredData);
            onChangeVal[i].examen_four_bool = false;
            setDataDiv(onChangeVal);
        } catch (Error) {

        }

    }

    const onSelectExamenFour = async (e, i) => {
        const onChangeVal = [...dataDiv];
        const selectedExamenOne = onChangeVal[i].examen_one;
        const selectedExamentwo = onChangeVal[i].examen_two;
        const selectedExamenthree = onChangeVal[i].examen_three;

        try {
            const filteredData = examens.filter(item =>
                !item.libelle_exam.includes(selectedExamenOne) && !item.libelle_exam.includes(selectedExamentwo) && !item.libelle_exam.includes(selectedExamenthree)
            );
            setExamensFour(filteredData);
        } catch (Error) {

        }

    }
    const handleExamen_three = (e, i) => {
        const { name, value } = e.target;
        const onChangeVal = [...dataDiv];
        onChangeVal[i].examen_three = value;
        onChangeVal[i].tabExamen.push(onChangeVal[i].examen_three);
        setDataDiv(onChangeVal);
    }

    // const handleValideAddExamenA = async (e, i) => {
    //     try {
    //         const { name, value } = e.target;
    //         const onChangeVal = [...dataDiv];
    //         // onChangeVal[i].examen_three = value;
    //         const plus = "+";
    //         if ((onChangeVal[i].modalite != "" && onChangeVal[i].modalite != undefined) && (onChangeVal[i].examen_one !== "" || onChangeVal[i].examen_two !== "" || onChangeVal[i].examen_three !== "" || onChangeVal[i].examen_four !== "")) {
    //             onChangeVal[i].categorie_fichier_one = onChangeVal[i].modalite + " " + onChangeVal[i].examen_one + (onChangeVal[i].examen_two !== "" ? "+" : "") + onChangeVal[i].examen_two + (onChangeVal[i].examen_three !== "" ? "+" : "") + onChangeVal[i].examen_three + (onChangeVal[i].examen_four !== "" ? "+" : "") + onChangeVal[i].examen_four;

    //             onChangeVal[i].message_error = "";
    //             onChangeVal[i].categorie_fichier_save = onChangeVal[i].categorie_fichier_one;
    //             onChangeVal[i].categorie_fichier = onChangeVal[i].categorie_fichier_one;

    //             const data_gains = {
    //                 categorie_fichier: onChangeVal[i].categorie_fichier_one,
    //                 site_id: site_id,
    //                 user_id: user_id,
    //             }

    //             const data_gains_second = {
    //                 categorie_fichier: onChangeVal[i].categorie_fichier_one,
    //             }

    //             const response_check = await axios.post(URL_AXIOS + "check_exam_existence", data_gains_second);
    //             if (response_check.data.status == 200) {
    //                 if (response_check.data.message === true) {
    //                     onChangeVal[i].msg_examen = "L'examen existe déjà!";
    //                     onChangeVal[i].bool_examen = true;
    //                 } else if (response_check.data.message === false) {
    //                     const response = await axios.post(URL_AXIOS + "addGains_CreationFolder", data_gains);
    //                     if (response.data.status == 200) {

    //                         Swal.fire({
    //                             title: "Réussi!",
    //                             text: "L'examen a été crée avec succès !" + response.data.message,
    //                             icon: "success"
    //                         });
    //                         liste_examens_gains();
    //                         onChangeVal[i].msg_examen = "";
    //                         onChangeVal[i].bool_examen = false;
    //                     }
    //                 }
    //             }




    //         } else {

    //             onChangeVal[i].message_error = "Veuillez sélectionner la modalité et au moins un examen";
    //         }

    //         setDataDiv(onChangeVal);

    //     } catch (error) {

    //     }

    // }
    const handleValideAddExamen = async (e, i) => {
        try {
            const { name, value } = e.target;
            const onChangeVal = [...dataDiv];
            const plus = "+";

            // Check if modalite and at least one exam field is not empty
            if (
                onChangeVal[i].modalite &&
                (onChangeVal[i].examen_one || onChangeVal[i].examen_two || onChangeVal[i].examen_three || onChangeVal[i].examen_four)
            ) {
                // Construct the categorie_fichier_one string
                onChangeVal[i].categorie_fichier_one =
                    onChangeVal[i].modalite + " " +
                    (onChangeVal[i].examen_one ? onChangeVal[i].examen_one + plus : "") +
                    (onChangeVal[i].examen_two ? onChangeVal[i].examen_two + plus : "") +
                    (onChangeVal[i].examen_three ? onChangeVal[i].examen_three + plus : "") +
                    (onChangeVal[i].examen_four ? onChangeVal[i].examen_four : "");

                // Clean up any trailing plus signs
                onChangeVal[i].categorie_fichier_one = onChangeVal[i].categorie_fichier_one.replace(/\+$/, '');

                onChangeVal[i].message_error = "";
                onChangeVal[i].categorie_fichier_save = onChangeVal[i].categorie_fichier_one;
                onChangeVal[i].categorie_fichier = onChangeVal[i].categorie_fichier_one;

                const data_gains = {
                    categorie_fichier: onChangeVal[i].categorie_fichier_one,
                    site_id: site_id,
                    user_id: user_id,
                };

                const data_gains_second = {
                    categorie_fichier: onChangeVal[i].categorie_fichier_one,
                    site_id: site_id
                };

                const response_check = await axios.post(URL_AXIOS + "check_exam_existence", data_gains_second);
                console.log("Response check data:", response_check.data); // Debugging log
                if (response_check.data.status === 200) {
                    if (response_check.data.message === true) {
                        onChangeVal[i].msg_examen = "L'examen existe déjà!";
                        onChangeVal[i].bool_examen = true;
                    } else if (response_check.data.message === false) {
                        const response = await axios.post(URL_AXIOS + "addGains_CreationFolder", data_gains);
                        if (response.data.status === 200) {
                            Swal.fire({
                                title: "Réussi!",
                                text: "L'examen a été crée avec succès ! ",
                                icon: "success"
                            });
                            liste_examens_gains();
                            //onChangeVal[i].msg_examen = "";
                            onChangeVal[i].bool_examen = false;
                        }
                    }
                }
            } else {
                onChangeVal[i].message_error = "Veuillez sélectionner la modalité et au moins un examen";
            }

            setDataDiv(onChangeVal);
        } catch (error) {
            console.error("Error validating exam addition:", error);
        }
    };

    const handleExamen_four = (e, i) => {
        const { name, value } = e.target;
        const onChangeVal = [...dataDiv];
        onChangeVal[i].examen_four = value;
        setDataDiv(onChangeVal);
    }


    const handleChangeNomcomplet = (e, i) => {
        const { value } = e.target;
        const onChangeVal = [...dataDiv];
        onChangeVal[i].nom_patient = value;
        if (onChangeVal[i].nom_patient === "") {
            onChangeVal[i].message_nomcomplet = "Veuillez saisir le nom complet svp";
            onChangeVal[i].bool_msg_nomcomplet = true;
        } else if (onChangeVal[i].nom_patient.length > 0) {
            onChangeVal[i].message_nomcomplet = '';
            onChangeVal[i].bool_msg_nomcomplet = false;
        }

        setDataDiv(onChangeVal);
    }

    const handleChangePrescripteur = (e, i) => {
        const { value } = e.target;
        const onChangeVal = [...dataDiv];
        onChangeVal[i].prescripteur = value;
        setDataDiv(onChangeVal);
    }
    const handleChangeHelpFile = (e, i) => {
        const file = e.target.files[0];
        //setSelectedImage(URL.createObjectURL(file));
        const onChangeVal = [...dataDiv];
        if (onChangeVal[i].imgSrc !== null || onChangeVal[i].imgSrc !== "") {
            onChangeVal[i].imgSrc = URL.createObjectURL(file);
            setDataDiv(onChangeVal);
            setVisibleFile(false)
        }



    }
    const handleChangeImgSrc = (e, i) => {
        const { value } = e.target;
        const onChangeVal = [...dataDiv];
        onChangeVal[i].imgSrc = value;

        if (onChangeVal[i].imgSrc.length === 0) {
            onChangeVal[i].message_imgSrc = "Veuillez capturer une photo ";
            onChangeVal[i].bool_msg_imgSrc = true;
        } else if (onChangeVal[i].imgSrc.length > 0) {
            onChangeVal[i].message_imgSrc = '';
            onChangeVal[i].bool_msg_imgSrc = false;
        }

        setDataDiv(onChangeVal);
    }




    const handleChangeCatExa = (value, i) => {
        //const { name, value } = e.target;
        const onChangeVal = [...dataDiv];
        onChangeVal[i].categorie_fichier = value;
        onChangeVal[i].categorie_fichier_save = onChangeVal[i].categorie_fichier;
        if (onChangeVal[i].categorie_fichier === "") {
            onChangeVal[i].message_categorie_fichier = "Veuillez saisir l'examen";
            onChangeVal[i].bool_msg_categorie_fichier = true;
        } else if (onChangeVal[i].categorie_fichier.length > 0) {
            onChangeVal[i].message_categorie_fichier = '';
            onChangeVal[i].categorie_fichier_one = "";
            onChangeVal[i].categorie_fichier_save = onChangeVal[i].categorie_fichier;
            onChangeVal[i].bool_msg_categorie_fichier = false;
        }
        setDataDiv(onChangeVal);
    }
    const handleVisible = (i) => {
        const onChangeVal = [...dataDiv];
        onChangeVal[i].bool = true;
        setDataDiv(onChangeVal);
    }
    const handleVisibleCapu = (i) => {
        const onChangeVal = [...dataDiv];
        onChangeVal[i].bool_large = true;
        setDataDiv(onChangeVal);
    }
    const handleVisibleCapuClose = (i) => {
        const onChangeVal = [...dataDiv];
        onChangeVal[i].bool_large = false;
        setDataDiv(onChangeVal);
    }
    const handleVisible_examen = (i) => {
        const onChangeVal = [...dataDiv];
        onChangeVal[i].bool_examen = true;
        setDataDiv(onChangeVal);
    }
    const handleVisible_obs = (i) => {
        const onChangeVal = [...dataDiv];
        onChangeVal[i].bool_obs = true;
        setDataDiv(onChangeVal);
    }
    const handleClose = (i) => {
        const onChangeVal = [...dataDiv];
        onChangeVal[i].bool = false;
        setDataDiv(onChangeVal);
    }
    const handleClose_obs = (i) => {
        const onChangeVal = [...dataDiv];
        onChangeVal[i].bool_obs = false;
        setDataDiv(onChangeVal);
    }

    const handleClose_examen = (i) => {
        const onChangeVal = [...dataDiv];
        onChangeVal[i].bool_examen = false;
        setDataDiv(onChangeVal);
    }

    const add_Observation = (value, i) => {

        const onChangeVal = [...dataDiv];
        onChangeVal[i].observation = value;
        onChangeVal[i].bool_obs = false;
        setDataDiv(onChangeVal);

    }

    // const handleZoomIn = (e) => {
    //     e.preventDefault();
    //     setZoomLevel(prevZoom => prevZoom + 0.1);
    // };


    // const handleZoomOut = (e) => {
    //     e.preventDefault();
    //     setZoomLevel(prevZoom => (prevZoom - 0.1 <= 0 ? 0.1 : prevZoom - 0.1));
    // };

    const data = examens.map(item => ({
        label: item.libelle_exam,
        value: item.libelle_exam
    }));
    const data_two = examensTwo.map(item => ({
        label: item.libelle_exam,
        value: item.libelle_exam
    }));
    const data_three = examensThree.map(item => ({
        label: item.libelle_exam,
        value: item.libelle_exam
    }));

    const data_four = examensFour.map(item => ({
        label: item.libelle_exam,
        value: item.libelle_exam
    }));


    //FIN DELARATION


    //WebCan 
    const webcamRef = useRef(null);
    // const [imgSrc, setImgSrc] = useState(null);
    // const [imageData, setImageData] = useState(null);
    const [mirrored, setMirrored] = useState(false);
    const retake = (e, index) => {
        e.preventDefault();
        const newDataDiv = [...dataDiv];
        newDataDiv[index].imgSrc = '';
        newDataDiv[index].imgScr_cap = '';
        setDataDiv(newDataDiv);
    };

    // const capture = useCallback((e, index) => {
    //     e.preventDefault();
    //     const imageSrc = webcamRef.current.getScreenshot();
    //     const newDataDiv = [...dataDiv];
    //     newDataDiv[index].imgSrc = imageSrc;
    //     setDataDiv(newDataDiv);
    // }, [webcamRef]);


    const onSelect = async (e, index) => {
        try {
            let _totalSize = 0;
            const select_files = e.files;
            const newDataDiv = [...dataDiv];

            if (select_files.length > 2) {
                newDataDiv[index].uploadedFile = [];
                newDataDiv[index].totalSize = _totalSize;
                //setDataDiv(newDataDiv);
            } else {
                for (const data_v of select_files) {
                    newDataDiv[index].uploadedFile.push(data_v);
                    _totalSize += data_v.size;

                }

                newDataDiv[index].totalSize = _totalSize;

            }
            if (newDataDiv[index].totalSize === 0) {
                newDataDiv[index].message_uploadedFile = "Veuillez saisir sélectionner les bulettins";
                newDataDiv[index].bool_msg_uploadedFile = true;
            } else if (newDataDiv[index].totalSize > 0) {
                newDataDiv[index].message_uploadedFile = "";
                newDataDiv[index].bool_msg_uploadedFile = false;

            }

            setDataDiv(newDataDiv);

        } catch (error) {
            // Handle errors
            //console.error("Error:", error);
        }
    };
    const resizeImageToA4 = async (imageUrl) => {
        // Load the image
        const img = new Image();
        img.src = imageUrl;

        // Create a canvas element
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Wait for the image to load
        await new Promise((resolve) => {
            img.onload = resolve;
        });

        // Set canvas dimensions to A4 size in pixels (assuming 300 DPI)
        const a4Width = 2480; // 210mm converted to pixels (210 * 300 / 25.4)
        const a4Height = 3508; // 297mm converted to pixels (297 * 300 / 25.4)

        // Calculate the aspect ratio of the image and A4 size
        const imgRatio = img.width / img.height;
        const a4Ratio = a4Width / a4Height;

        let srcX = 0, srcY = 0, srcWidth = img.width, srcHeight = img.height;

        // Determine the new dimensions and cropping while maintaining the aspect ratio
        if (imgRatio > a4Ratio) {
            // Image is wider than A4
            srcWidth = img.height * a4Ratio;
            srcX = (img.width - srcWidth) / 2;
        } else {
            // Image is taller than A4
            srcHeight = img.width / a4Ratio;
            srcY = (img.height - srcHeight) / 2;
        }

        // Set canvas dimensions to A4 size
        canvas.width = a4Width;
        canvas.height = a4Height;

        // Clear the canvas and draw the cropped image onto the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, srcX, srcY, srcWidth, srcHeight, 0, 0, a4Width, a4Height);

        // Convert canvas content to a Blob
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                resolve(blob);
            }, 'image/jpeg');
        });
    };

    const add_folder = async () => {
        try {
            let isValid = true;
            const formDataObject = {};

            for (const [index, dataItem] of dataDiv.entries()) {
                if (isValid) {
                    setStatusEnvoiFolder(true);
                    const blob = await resizeImageToA4(dataItem.imgSrc);
                    const imageFile = new File([blob], `image_${index}.jpg`, { type: 'image/jpeg' });

                    formDataObject[index] = {
                        nom_patient: dataItem.nom_patient.toUpperCase(),
                        sexe: dataItem.sexe,
                        age: dataItem.age,
                        libelle_age: dataItem.libelle_age,
                        prescripteur: dataItem.prescripteur.toUpperCase(),
                        categorie_fichier: dataItem.categorie_fichier_save,
                        admin: "N/A",
                        user_id: user_id,
                        etat: dataItem.etat,
                        observation: dataItem.observation,
                        exp: "N",
                        file: imageFile,
                        sites_membres_id: medecinradio,
                        file_zip: dataItem.uploadedFile || null,
                        manipulateur_id: dataItem.selected,
                        site_id: site_id,
                        categorie_fichier_one: dataItem.categorie_fichier_save,
                        selectedCentreDeSoin: dataItem.selectedCentreDeSoin
                    };
                } else {
                    setCheckStatusOfFields(false);
                    isValid = false;
                    return;
                }
            }

            if (isValid) {
                // setCheckStatusOfFields(true);

                const response = await axios.post(URL_AXIOS + 'upload_files', { formDataObject }, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    onUploadProgress: ({ loaded, total }) => {
                        if (isValid) {
                            const value_loaded = Math.floor((loaded / total) * 100);
                            setValueProgressBar(value_loaded);
                            setVisibleProgressBar(true);
                            if (loaded === total) setVisibleProgressBar(false);
                        }

                    }
                });

                if (response.data.status === 200) {
                    Swal.fire({
                        title: "Réussi!",
                        text: "Les dossiers ont été envoyés avec succès !",
                        icon: "success"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            liste_dossiers(site_id);
                            QuestionNotification();
                        }
                    });

                    setSaveStatus(true);
                    fileUploadRef.current.clear();
                    setStatusEnvoiFolder(false);
                    const newDataDiv = {
                        nom_patient: "",
                        categorie_fichier: "",
                        prescripteur: "",
                        uploadedFile: [],
                        imgSrc: "",
                        imgScr_cap: "",
                        sexe: "",
                        libelle_age: "",
                        age: "",
                        selected: [],
                        totalSize: 0,
                        observation: "",
                        categorie_fichier_one: "",
                        examen_one: "",
                        examen_two: "",
                        examen_three: "",
                        examen_four: "",
                        selectedCentreDeSoin: null
                    };

                    setDataDiv([newDataDiv]);
                } else {
                    setVisibleProgressBar(false);
                    setStatusEnvoiFolder(false);
                    alert("Erreur lors de l'envoi des dossiers.");
                    Swal.fire({
                        title: "Info!",
                        text: "Erreur lors de l'envoi des dossiers.",
                        icon: "info"
                    })
                }
            } else {
                alert("Problème")
            }
        } catch (error) {
            console.error("Erreur lors de l'ajout du dossier:", error.message);
            setStatusEnvoiFolder(false);
        }
    };

    // const add_folderA = async () => {

    //     try {
    //         let dataVerif = [];
    //         const formDataObject = {};

    //         for (const [index, dataItem] of dataDiv.entries()) {
    //             // if(dataItem.imgScr_cap)
    //             // const blob = await fetch(dataItem.imgScr_cap).then((res) => res.blob());
    //             const blob = await resizeImageToA4(dataItem.imgSrc);
    //             const imageFile = new File([blob], `image_${index}.jpg`, { type: 'image/jpeg' });
    //             formDataObject[index] = {
    //                 nom_patient: dataItem.nom_patient,
    //                 sexe: dataItem.sexe,
    //                 age: dataItem.age,
    //                 libelle_age: dataItem.libelle_age,
    //                 prescripteur: dataItem.prescripteur,
    //                 categorie_fichier: dataItem.categorie_fichier_save,
    //                 admin: "N/A",
    //                 user_id: user_id,
    //                 etat: dataItem.etat,
    //                 observation: dataItem.observation,
    //                 exp: "N",
    //                 file: imageFile,
    //                 sites_membres_id: medecinradio,
    //                 file_zip: dataItem.uploadedFile || null,
    //                 manipulateur_id: dataItem.selected,
    //                 site_id: site_id,
    //                 categorie_fichier_one: dataItem.categorie_fichier_save
    //             };

    //             if (dataItem.nom_patient === "") {
    //                 dataItem.message_nomcomplet = "Veuillez saisir le nom complet svp";
    //                 dataItem.bool_msg_nomcomplet = true;
    //             }
    //             if (dataItem.categorie_fichier === "" && dataItem.categorie_fichier_one === "") {
    //                 dataItem.message_categorie_fichier = "Veuillez saisir l'examen";
    //                 dataItem.bool_msg_categorie_fichier = true;
    //             }

    //             if (dataItem.age === "") {
    //                 // dataItem.message_a = "Veuillez saisir l'examen";
    //                 // dataItem.bool_msg_categorie_fichier = true;
    //             }
    //             // if (dataItem.uploadedFile.length <= 0) {
    //             //     dataItem.message_uploadedFile = "Veuillez sélectionner les bulletins";
    //             //     dataItem.bool_msg_uploadedFile = true;
    //             // }
    //             if (dataItem.selected.length === 0) {
    //                 dataItem.message_mani = "Veuillez sélectionner le manipulateurs";
    //                 dataItem.bool_mani = true;
    //             }
    //             if (dataItem.imgSrc === "" && dataItem.filehelp === "") {
    //                 dataItem.message_imgSrc = "Veuillez capturer une photo";
    //                 dataItem.bool_msg_imgSrc = true;
    //             }
    //             if (dataItem.nom_patient !== "" && (dataItem.imgSrc !== "") && dataItem.selected.length > 0 && medecinradio !== "" && dataItem.age !== "" && dataItem.sexe !== "") {
    //                 /// status to check if it's true
    //                 setCheckStatusOfFields(true);
    //             } else {
    //                 setCheckStatusOfFields(false);
    //                 alert("Veuillez saisir toutes les informations obligatoire")
    //                 // break;
    //             }
    //         }
    //         if (checkStatusOfFields) {
    //             const response = await axios.post(URL_AXIOS + 'upload_files', {
    //                 formDataObject
    //             }, {
    //                 headers: {
    //                     'Content-Type': 'multipart/form-data',
    //                 },
    //                 onUploadProgress: ({ loaded, total }) => {
    //                     const value_loaded = Math.floor((loaded / total) * 100);
    //                     setValueProgressBar(value_loaded);
    //                     setVisibleProgressBar(true);
    //                     if (loaded == total) {

    //                         setVisibleProgressBar(false)
    //                     }
    //                 }
    //             });
    //             if (response.data.status === 200) {
    //                 Swal.fire({
    //                     title: "Réussi!",
    //                     text: "Les dossiers ont été envoyés avec succès !",
    //                     icon: "success"
    //                 }).then((result) => {
    //                     if (result.isConfirmed) {
    //                         liste_dossiers(site_id);
    //                         QuestionNotification();
    //                     }
    //                 });
    //                 setSaveStatus(true);
    //                 fileUploadRef.current.clear();
    //                 const onChangeVal = [...dataDiv];
    //                 onChangeVal[0].nom_patient = "";
    //                 onChangeVal[0].categorie_fichier = "";
    //                 onChangeVal[0].prescripteur = "";
    //                 onChangeVal[0].uploadedFile = [];
    //                 onChangeVal[0].imgSrc = "";
    //                 onChangeVal[0].imgScr_cap = "";
    //                 onChangeVal[0].sexe = "";
    //                 onChangeVal[0].libelle_age = "";
    //                 onChangeVal[0].age = "";
    //                 onChangeVal[0].selected = [];
    //                 onChangeVal[0].totalSize = 0;
    //                 onChangeVal[0].observation = "";
    //                 onChangeVal[0].categorie_fichier_one = "";
    //                 onChangeVal[0].examen_one = "";
    //                 onChangeVal[0].examen_two = "";
    //                 onChangeVal[0].examen_three = "";
    //                 onChangeVal[0].examen_four = "";

    //                 const newDataDiv = [dataDiv[0]];
    //                 setDataDiv(newDataDiv);
    //                 // onTemplateClear(0);
    //                 //deleteDiv(0)
    //                 // setDataDiv([])
    //                 //const emptyArray = [];
    //                 //setDataDiv(onChangeVal);


    //             } else {
    //                 //alert("Erreur ");

    //             }

    //         }
    //     } catch (error) {
    //         console.log("Erreur thi" + error.message);
    //     }
    // };

    const QuestionNotification = () => {
        try {
            Swal.fire({
                title: "Question",
                text: "Voulez-vous notifier le médécin ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: 'Non'
            }).then((result) => {
                if (result.isConfirmed) {

                    setVisibleMessage(true)
                } else {
                    setVisibleMessage(false)
                }

            });
        } catch (error) {

        }
    }
    const confirmCreateFolder = () => {
        try {
            let isValid = true;
            const formDataObject = {};

            Swal.fire({
                title: "Question",
                text: "Êtes-vous sûr d'envoyer ce(s) dossier(s) ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui",
                cancelButtonText: 'Non'
            }).then((result) => {
                if (result.isConfirmed) {
                    for (const [index, dataItem] of dataDiv.entries()) {
                        if (!dataItem.nom_patient) {
                            dataItem.message_nomcomplet = "Veuillez saisir le nom complet svp";
                            dataItem.bool_msg_nomcomplet = true;
                            isValid = false;
                        } else {
                            dataItem.bool_msg_nomcomplet = false;
                            dataItem.message_nomcomplet = "";
                        }
                        if (!dataItem.categorie_fichier && !dataItem.categorie_fichier_one) {
                            dataItem.message_categorie_fichier = "Veuillez saisir l'examen";
                            dataItem.bool_msg_categorie_fichier = true;
                            isValid = false;
                        } else {
                            dataItem.bool_msg_categorie_fichier = false;
                            dataItem.message_categorie_fichier = "";
                        }
                        if (!dataItem.age) {
                            dataItem.message_age = "Veuillez saisir l'âge";
                            dataItem.bool_msg_age = true;
                            isValid = false;
                        } else {
                            dataItem.bool_msg_age = false;
                            dataItem.message_age = "";
                        }
                        if (dataItem.selected.length === 0) {
                            dataItem.message_mani = "Veuillez sélectionner les manipulateurs";
                            dataItem.bool_mani = true;
                            isValid = false;
                        } else {
                            dataItem.bool_mani = false;
                            dataItem.message_mani = "";
                        }
                        if (!dataItem.imgSrc && !dataItem.filehelp) {
                            dataItem.message_imgSrc = "Veuillez capturer une photo";
                            dataItem.bool_msg_imgSrc = true;
                            isValid = false;
                        } else {
                            dataItem.bool_msg_imgSrc = false;
                            dataItem.message_imgSrc = "";
                        }

                        if (!dataItem.sexe) {
                            dataItem.message_sexe = "Veuillez cocher le sexe";
                            dataItem.bool_msg_sexe = true;
                            isValid = false;
                        } else {
                            dataItem.bool_msg_sexe = false;
                            dataItem.message_sexe = "";
                        }

                        if (!dataItem.selectedCentreDeSoin) {
                            dataItem.message_selectedCentreDeSoin = "Veuillez sélectionner le centre de soin";
                            dataItem.bool_msg_selectedCentreDeSoin = true;
                            isValid = false;
                        } else {
                            dataItem.bool_msg_selectedCentreDeSoin = false;
                            dataItem.message_selectedCentreDeSoin = "";
                        }
                    }
                    if (isValid) {
                        setVisibleProgressBar(true);
                        add_folder();
                    } else {

                    }



                    // Perform validation checks and prepare form data



                    //handleEmptyFields()

                }

            });
        } catch (error) {

        }
    }


    const onTemplateUpload = (e, i) => {
        alert("Je suis toujours là et j'ai la foi de pouvoir ")
        const newDataDiv = [...dataDiv];
        let _totalSize = 0;
        newDataDiv[i].uploadedFile.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };
    const onTemplateRemove = (file, callback, index) => {
        const newDataDiv = [...dataDiv];

        let totalSize_data = 0;
        let data_value = 0;
        let result_data = 0;
        totalSize_data = newDataDiv[index].totalSize;
        result_data = totalSize_data - file.size;
        newDataDiv[index].totalSize = result_data;

        const newDataDivUpload = [...newDataDiv[index].uploadedFile];
        for (let i = 0; i < newDataDivUpload.length; i++) {
            const element = newDataDivUpload[i];
            newDataDivUpload.splice(i, 1); // Remove the element at index i
            break;
        }
        newDataDiv[index].uploadedFile = newDataDivUpload; // Move this line outside the loop
        setDataDiv(newDataDiv);
        callback();
    };

    const onTemplateClear = (index) => {

        const newDataDiv = [...dataDiv];
        newDataDiv[index].totalSize = 0;
        newDataDiv[index].uploadedFile = [];
        setDataDiv(newDataDiv);
    };
    const headerTemplate = (options, index) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const newDataDiv = [...dataDiv];
        const value = newDataDiv[index].totalSize / 500000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(value) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'left' }}>
                {chooseButton}
                {cancelButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatFileSize(newDataDiv[index].totalSize)} / 5 GB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };



    const itemTemplate = (file, props, index) => {

        return (
            <div className="flex align-items-center flex-wrap" >

                <div className="flex align-items-center" style={{ width: '40%', height: '10px' }}>

                    <i className='fa fa-file' style={{ fontSize: 20 }}>&nbsp;</i>
                    <span className="flex flex-column text-left ml-3">
                        {file.name}

                        &nbsp;&nbsp;<small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove, index)} />

            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column" style={{ height: "108px" }}>
                <i className="pi pi-file mt-3 p-5" style={{ fontSize: '2em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-2">
                    Veuillez déposer vos fichiers ici
                </span>
            </div>
        );
    };
    const chooseOptions = {
        icon: 'pi pi-fw pi-paperclip',
        iconOnly: true,
        className: 'custom-choose-btn p-button-rounded p-button-outlined',
    };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };


    const formatFileSize = (bytes, decimalPoint) => {
        if (bytes === 0) return '0 Bytes';
        var k = 1000,
            dm = decimalPoint || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }




    const liste_examens_gains = async () => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_gainsBySite/${site_id}`);
            if (response.data.status == 200) {
                setGains(response.data.gains);
            }
        } catch (Error) {

        }
    }
    const liste_examens = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'liste_examen');
            if (response.data.status == 200) {
                setExamens(response.data.examens);
            }
        } catch (Error) {

        }
    }
    const lister_contrat = async () => {
        try {

            const response = await axios.get(URL_AXIOS + `agendaContrat/${site_id}`);
            if (response.data.status === 200) {
                setSiteMembre(response.data.data_membres_sites);
                // setLoading(false)
            } else {

            }
        } catch (Error) {

        }
    };

    const handleMultiSelectChange = (selected, index) => {

        const newDataDiv = [...dataDiv];
        newDataDiv[index].selected = selected;
        if (newDataDiv[index].selected.length === 0) {
            newDataDiv[index].message_mani = "Veuillez sélectionner le manipulateur";
            newDataDiv[index].bool_mani = true;
        } else if (newDataDiv[index].selected.length > 0) {
            newDataDiv[index].message_mani = '';
            newDataDiv[index].bool_mani = false;
        }
        setDataDiv(newDataDiv);
    };
    const ListeMedecinsBySite = () => {
        return (
            <div style={{ height: 'auto', backgroundColor: '#057FB2', width: 'auto', position: 'absolute' }}>

                {siteMembre.map((sitemembre) => {
                    return (
                        <ul className='navbar navbar-nav' style={{ padding: 5 }}>
                            <div className="flex align-items-center" key={sitemembre.sites_membres_id}>
                                <RadioButton inputId={`sitemembre_${sitemembre.sites_membres_id}`} disabled={true} name={sitemembre.sites_membres_id} value={sitemembre.sites_membres_id} onChange={(e) => setMedecinradio(e.value)} checked={medecinradio === sitemembre.sites_membres_id} />
                                <label htmlFor={`sitemembre_${sitemembre.sites_membres_id}`} style={{ color: '#fff', fontWeight: 'bolder', marginLeft: 10 }} className="ml-2">{sitemembre.nom + " " + sitemembre.prenom}</label>

                            </div>

                        </ul>

                    );
                })}

            </div>

        )
    }
    const bodyActionStatus = (data) => {
        return (
            <div style={{ display: 'flex', }}>
                {data.statutag == 0 ? "En cours " : data.statutag == 1 ? "En attente" : "Expiré"}
            </div>
        )
    }
    const liste_agenda = async (id_site) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_site_membre_agendaBySite/${id_site}`);
            if (response.data.status === 200) {
                setAgenda(response.data.list_site_membre_agenda);
            }
        } catch (Error) {

        }

    }
    const liste_dossiers = async (id_site) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_dossiersBySite/${id_site}`);
            if (response.data.status == 200) {
                setDossiers(response.data.dossier_send);

            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }

    const onSelectExamenOne = async (e, i) => {

        try {
            const onChangeVal = [...dataDiv];
            if (onChangeVal[i].modalite !== "") {
                onChangeVal[i].examen_one_bool = false;
            } else {
                onChangeVal[i].examen_one_bool = true;
            }



        } catch (Error) {

        }

    }
    const WhatssappButton = () => {

        const phoneNumber = numberDoc;
        let defaultMessage = 'Salut! Comment pouvons-nous vous aider?';
        if (selectedMessage !== "") {
            defaultMessage = selectedMessage;
        } else {

        }

        const encodedMessage = encodeURIComponent(defaultMessage);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

        return (
            <a href={whatsappURL} className='btn btn-info form-control' target="_blank" rel="noopener noreferrer">
                <i className="icofont-whatsapp mr-2"></i>
                <span>Envoyer</span>
            </a>
        );
    };
    const closeVisible = () => {
        setVisibleMessage(false);
        navigate('/resultat_transfere');
    }

    const checkFieldsExistingOfData = (i) => {
        const newDataDiv = [...dataDiv];
        if (newDataDiv[i].categorie_fichier !== "" && newDataDiv[i].nom_patient !== "" && newDataDiv[i].age !== "" && newDataDiv[i].sexe !== "" && newDataDiv[i].selected.length > 0 && newDataDiv[i].imgSrc !== "") {
            setBoolIncr(false)
        } else {
            setBoolIncr(true)
        }
    }

    const liste_agenda_programmer = async (id_site) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_site_membre_agendaBySiteAndSet/${id_site}`);
            if (response.data.status === 200) {
                setMedecinradio(response.data.list_site_membre_agenda.sites_membres_id);
            }
        } catch (Error) {

        }

    }
    useEffect(() => {
        liste_centre_des_soins(ville)
    }, [])
    useEffect(() => {
        for (let index = 0; index < dataDiv.length; index++) {
            checkFieldsExistingOfData(index)
        }
    }, [dataDiv])
    useEffect(() => {
        setMessage_one(initialMessageOne);
        setMessage_two(initialMessageTwo);
    }, [societe])
    useEffect(() => {
        // Simulate a loading process (e.g., data fetching)
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000); // Adjust the timeout as needed

        return () => clearTimeout(timer); // Cleanup the timer on unmount
    }, []);
    useEffect(() => {
        if (site_id) {
            liste_agenda_programmer(site_id);
        }
    }, [site_id]);
    useEffect(() => {
        liste_examens();
        lister_contrat();
        fetchMembres();
        liste_agenda(site_id)
        liste_examens_gains();
        liste_site_contrat_get_numberMedecin(site_id, medecinradio);
        liste_modalite()
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, [site_id, medecinradio]);


    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected} />
            <Dialog header="Messages" visible={visibleMessage} style={{ width: '50vw' }} onHide={() => closeVisible()}>
                <div style={{ display: "flex", }}>
                    <RadioButton inputId="ingredient1" style={{ marginTop: 20 }} name="pizza" value={message_one} onChange={(e) => setSelectedMessage(e.value)} checked={selectedMessage === message_one} />
                    {/* <p style={{ marginLeft: 10 }}>
                       [E-METECH] Bonjour Docteur, les examens de {societe} sont disponibles sur la plateforme.
                    </p> */}
                    <WhatssapSmsOne societe={societe} />
                </div>
                <Divider />
                <div style={{ display: "flex", }}>
                    <RadioButton inputId="ingredient2" style={{ marginTop: 20 }} name="pizza" value={message_two} onChange={(e) => setSelectedMessage(e.value)} checked={selectedMessage === message_two} />
                    {/* <p style={{ marginLeft: 10 }}>
                        <span style={{ color: 'red', fontWeight: "bold" }}>[E-METECH] (Urgent)</span> Bonjour Docteur, les examens de {societe} sont disponibles sur la plateforme .

                    </p> */}
                    <WhatssapSmsTwo societe={societe} />
                </div>

                {WhatssappButton()}
            </Dialog>
            <Dialog header="Agenda" visible={visibleAgenda} style={{ width: '80vw' }} onHide={() => setVisibleAgenda(false)}>
                <DataTable value={agenda} paginator rows={5} rowClassName={(data) => data.statutag == 0 ? "bgColorRowAgenda" : data.statutag == 1 ? "bgColorRowAbonnProgramm" : "bgColorRowAbonnExp"} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}>

                    <Column header={<div style={{ marginLeft: 25 }}>#</div>} field='id_contrat' headerStyle={{ marginLeft: 100 }} bodyStyle={{ textAlign: 'center' }} style={{ width: '5%', height: '40px' }}></Column>
                    <Column field='societe' header="CENTRE" style={{ width: 'auto', height: '40px' }}></Column>
                    <Column field={'nom'} header="NOM" style={{ width: 'auto', height: '40px' }}></Column>
                    <Column field={'prenom'} header="PRENOM" style={{ width: 'auto', height: '40px' }}></Column>
                    <Column field='datedebut' header="DATE DEBUT" style={{ width: 'auto', height: '40px' }}></Column>
                    <Column field='datefin' header="DATE FIN" style={{ width: 'auto', height: '40px' }}></Column>
                    <Column field='created_at' header="DATE" style={{ width: 'auto', height: '40px' }}></Column>
                    <Column header="STATUS" body={bodyActionStatus} style={{ width: 'auto', height: '40px' }}></Column>
                    {/* <Column header="ACTIONS" body={bodyAction} style={{ width: 'auto', height: '40px' }}></Column> */}
                </DataTable>
            </Dialog>
            <Dialog header="Envoi en cours..." visible={visibleProgressBar} draggable={false} modal={true} style={{ width: '50vw' }} closable={false} onHide={() => setVisibleProgressBar(false)}>
                <ProgressBar value={valueProgressBar}></ProgressBar>
            </Dialog>
            {loading ? <Loading /> : <section id="main-content">
                <section class="wrapper ">
                    <div className='row' style={{ marginLeft: "2px" }}>
                        <div className='col col-md-10'>
                            {siteMembre.length > 0 ? ListeMedecinsBySite() : <span className='alert alert-warning' style={{ marginTop: 30 }}>Veuillez contacter l'administrateur pour la configuration des médecins.</span>}
                        </div>
                        <div className='col col-md-2'>
                            <div style={{ float: 'right' }}>
                                <button class="btn btn-success" type="button" title='Ajouter dossier' disabled={boolIncr} onClick={handleIncrement} style={{ marginTop: '5px', marginLeft: 10 }}>
                                    <i class="fa fa-plus"></i>
                                </button>
                                <button class="btn btn-primary" type="button" title='Agenda' onClick={() => setVisibleAgenda(true)} style={{ marginTop: '5px', marginLeft: 10 }} >
                                    <i class="fa fa-book"></i>
                                </button>
                                {/* <button type='button' onClick={() => confirmCreateFolder()} class="btn btn-warning" disabled={jourRestant == 0 ? true : false} style={{ marginTop: '5px', marginLeft: 10 }} title="Transferer le dossier">
                                    <i class=" fa fa-upload"></i>
                                </button> */}
                            </div>

                        </div>
                    </div>

                    <br />
                    {
                        dataDiv.map((val, index) => (
                            <div className='container-fluid'>

                                {index > 0 && <div className='row' style={{ height: "10px", backgroundColor: 'white' }}><button onClick={() => deleteDiv(index)} class="btn btn-danger" type="button" style={{ marginTop: '1px', float: 'right', fontSize: 12 }}><i class="now-ui-icons ui-1_simple-remove fa fa-times"></i></button></div>}
                                <div className='row card' style={{ height: "auto" }}>

                                    <div className='col col-md-4' style={{ padding: 15 }}>
                                        <div className='row'>

                                            <div className='col col-md-10'>
                                                <Dropdown value={val.categorie_fichier}
                                                    name='categorie_fichier'
                                                    onChange={(e) => handleChangeCatExa(e.value, index)}
                                                    options={gains}
                                                    optionLabel="categorie_fichier"
                                                    optionValue='categorie_fichier'
                                                    placeholder="Sélectionnez examen *"
                                                    filter className="w-full "
                                                    style={{ fontWeight: 'bold', marginTop: "-6px" }}
                                                    emptyMessage='Aucun examen disponible'
                                                />
                                                {val.bool_msg_categorie_fichier && <label style={{ color: 'red' }}>{val.message_categorie_fichier}</label>}

                                            </div>
                                            <div className='col col-md-2'>
                                                <button type='button'
                                                    style={{ marginLeft: "12px", marginTop: "-6px" }}
                                                    className='btn btn-primary'
                                                    onClick={() => handleVisible_examen(index)}
                                                    title='Créer un examen'
                                                >
                                                    <i className='fa fa-plus'></i>
                                                </button>

                                                <Dialog header="Ajout examen" visible={val.bool_examen} onChange={(e) => handleChange(e, index)} style={{ width: '30vw' }} onHide={() => handleClose_examen(index)}>
                                                    <div className="flex flex-column">
                                                        {val.msg_examen != "" && <div style={{ width: "100%" }}>
                                                            <center><div className='alert alert-danger'>{val.msg_examen}</div></center>
                                                        </div>}
                                                        {val.message_error != "" && <div style={{ width: "100%" }}>
                                                            <center><div className='alert alert-danger'>{val.message_error}</div></center>
                                                        </div>}
                                                        <div class="flex ">
                                                            <Dropdown value={val.modalite}
                                                                name='libelleModalite'
                                                                onChange={(e) => handleModalite(e, index)}
                                                                options={modalite}
                                                                optionLabel="code"
                                                                optionValue='code'
                                                                placeholder="Sélectionnez modalité *"
                                                                filter className="w-full "
                                                                onSelect={(e) => onSelectExamenOne(e, index)}
                                                            />
                                                            {/* <select className='form-control' value={val.modalite} name="modalite"
                                                                style={{ fontWeight: 'bold' }}
                                                                onChange={(e) => handleModalite(e, index)}>
                                                                <option style={{ fontWeight: 500 }} value={""}>Modalité</option>
                                                                <option style={{ fontWeight: 500 }} value={"SCANNER"}>SCANNER</option>
                                                                <option style={{ fontWeight: 500 }} value={"IRM"}>IRM</option>
                                                                <option style={{ fontWeight: 500 }} value={"RX"}>RADIOGRAPHIE</option>
                                                                <option style={{ fontWeight: 500 }} value={"ECHOGRAPHIE"}>ECHOGRAPHIE</option>
                                                            </select> */}
                                                        </div>
                                                        <div className="flex align-items-center justify-content-center  ">
                                                            <h5>+</h5>
                                                        </div>
                                                        <div className="flex ">
                                                            <Dropdown value={val.examen_one}
                                                                name='examen_one'
                                                                onChange={(e) => handleExamen_one(e, index)}
                                                                options={examens} optionLabel="libelle_exam" optionValue='libelle_exam'
                                                                placeholder="Sélectionnez examen 1 *"
                                                                filter className="w-full "
                                                                disabled={val.examen_one_bool}
                                                            />
                                                        </div>
                                                        <div className="flex align-items-center justify-content-center  ">
                                                            <h5>+</h5>
                                                        </div>
                                                        <div className="flex ">
                                                            <Dropdown
                                                                value={val.examen_two}
                                                                name='examen_two'
                                                                onChange={(e) => handleExamen_two(e, index)}
                                                                options={data_two}
                                                                optionLabel="label"
                                                                optionValue='label'
                                                                placeholder="Sélectionnez examen 2"
                                                                filter className="w-full "
                                                                onSelect={(e) => onSelectExamenTwo(e, index)}
                                                                disabled={val.examen_two_bool}

                                                            />
                                                        </div>
                                                        <div className="flex align-items-center justify-content-center  ">
                                                            <h5>+</h5>
                                                        </div>
                                                        <div className="flex ">
                                                            <Dropdown
                                                                value={val.examen_three}
                                                                name='examen_three'
                                                                onChange={(e) => handleExamen_three(e, index)}
                                                                options={data_three}
                                                                optionLabel="label"
                                                                optionValue='label'
                                                                placeholder="Sélectionnez examen 3"
                                                                filter className="w-full "
                                                                onSelect={(e) => onSelectExamenThree(e, index)}
                                                                disabled={val.examen_three_bool}

                                                            />

                                                        </div>
                                                        <div className="flex align-items-center justify-content-center  ">
                                                            <h5>+</h5>
                                                        </div>
                                                        <div className="flex ">
                                                            <Dropdown
                                                                value={val.examen_four}
                                                                name='examen_four'
                                                                onChange={(e) => handleExamen_four(e, index)}
                                                                options={data_four}
                                                                optionLabel="label"
                                                                optionValue='label'
                                                                placeholder="Sélectionnez examen 4"
                                                                filter className="w-full "
                                                                onSelect={(e) => onSelectExamenFour(e, index)}
                                                                disabled={val.examen_four_bool}

                                                            />

                                                        </div>
                                                        <div className="flex ">
                                                            <button type='button' onClick={(e) => handleValideAddExamen(e, index)} className='btn btn-primary form-control mt-5' >Enregistrer</button>
                                                        </div>
                                                    </div>
                                                </Dialog>
                                            </div>

                                        </div>

                                        <div className='row'>
                                            <div className='col col-md-12'>

                                                <input className="form-control   " placeholder='Noms complets *'
                                                    value={val.nom_patient} onChange={(e) => handleChangeNomcomplet(e, index)}
                                                    name='nom_patient'
                                                    style={{ fontWeight: 500 }}
                                                />
                                                {val.bool_msg_nomcomplet && <label style={{ color: 'red' }}>{val.message_nomcomplet}</label>}
                                            </div>

                                        </div>
                                        <div className='row'>
                                            <div className="col col-md-6" style={{ display: 'flex', flexDirection: 'row' }}>
                                                <input type='number' placeholder='Age *'
                                                    value={val.age} onChange={(e) => handleChangeAge(e, index)}
                                                    className='form-control'
                                                    name='age'
                                                    style={{ fontSize: "12px", fontWeight: 500 }}
                                                />

                                            </div>
                                            <div className=" col col-md-6">
                                                <select className='form-control' value={val.libelle_age}
                                                    onChange={(e) => handleChange(e, index)}
                                                    name='libelle_age'
                                                    style={{ fontWeight: 500 }}
                                                >
                                                    <option style={{ fontWeight: 500 }} value={'an(s)'}>an(s)</option>
                                                    <option style={{ fontWeight: 500 }} value={'Mois'}>Mois</option>
                                                    <option style={{ fontWeight: 500 }} value={'Jour(s)'}>Jour(s)</option>
                                                    <option style={{ fontWeight: 500 }} value={'Heure(s)'}>Heure(s)</option>
                                                </select>

                                            </div>
                                            {val.bool_msg_age && <label style={{ color: 'red', marginLeft: 9 }}>{val.message_age}</label>}
                                        </div>
                                        <div className='row'>
                                            <div className='col col-md-12'>
                                                <div style={{ display: "flex", position: 'relative' }}>
                                                    <div class="flex  flex-wrap">
                                                        <div class="flex align-items-center justify-content-center " style={{ marginLeft: 10 }}>
                                                            <RadioButton inputId="ingredient1" name="sexe" value={"M"} onChange={(e) => handleChangeSexe(e, index)} checked={val.sexe === 'M'} />
                                                            <label htmlFor="ingredient1" style={{ marginLeft: 5, marginTop: 2, fontWeight: 500 }} className="ml-2">Homme</label>
                                                        </div>
                                                        <div class="flex align-items-center justify-content-center " style={{ marginLeft: 20 }}>
                                                            <RadioButton inputId="ingredient2" name="sexe" value={"F"} onChange={(e) => handleChangeSexe(e, index)} checked={val.sexe === 'F'} />
                                                            <label htmlFor="ingredient2" style={{ marginLeft: 5, marginTop: 2, fontWeight: 500 }} className="ml-2">Femme</label>
                                                        </div>

                                                    </div>





                                                </div>
                                                {val.bool_msg_sexe && <label style={{ color: 'red' }}>{val.message_sexe}</label>}
                                            </div>

                                        </div>

                                        <div className='row'>
                                            <div className='col col-md-12'>
                                                <input type='text' className='form-control' placeholder='Prescripteur '
                                                    value={val.prescripteur} style={{ fontWeight: 500 }} name='prescripteur' onChange={(e) => handleChangePrescripteur(e, index)}
                                                />

                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col col-md-12'>
                                                <div style={{ display: "flex", position: 'relative' }}>
                                                    <div class="flex  flex-wrap">
                                                        <div class="flex align-items-center justify-content-center " style={{ marginLeft: 10 }}>
                                                            <label style={{ marginTop: 10, fontWeight: 500 }}>Urgence:</label>
                                                        </div>
                                                        <div class="flex align-items-center justify-content-center " style={{ marginLeft: 10 }}>
                                                            <RadioButton inputId="ingredient1" style={{ marginLeft: 5, marginTop: 10 }} name="etat" value={1} onChange={(e) => handleChange(e, index)} checked={val.etat === 1} />
                                                            <label htmlFor="ingredient1" className="ml-2" style={{ marginLeft: 5, marginTop: 10, fontWeight: 500 }}>Oui</label>
                                                        </div>
                                                        <div class="flex align-items-center justify-content-center " style={{ marginLeft: 10 }}>
                                                            <RadioButton inputId="ingredient2" style={{ marginLeft: 5, marginTop: 10 }} name="etat" value={0} onChange={(e) => handleChange(e, index)} checked={val.etat === 0} />
                                                            <label htmlFor="ingredient2" className="ml-2" style={{ marginLeft: 5, marginTop: 10, fontWeight: 500 }}>Non</label>
                                                        </div>
                                                        <div class="flex align-items-center justify-content-center " style={{ marginLeft: 10 }}>
                                                            <button type='button' className='btn btn-info' style={{ fontSize: 12, marginLeft: 15 }} name="bool_obs" onClick={() => handleVisible_obs(index)}>Obseravtion</button>
                                                        </div>
                                                    </div>





                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col col-md-5' style={{ padding: 15 }}>
                                        <div className='row'>
                                            <div>

                                                <Toast ref={toast}></Toast>
                                                <Tooltip target=".custom-choose-btn" content="Sélectionner un examen en format zip." position="bottom" />
                                                <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
                                                <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

                                                <FileUpload
                                                    ref={fileUploadRef}
                                                    name={`uploadedFile[${index}]`}
                                                    accept=".zip"

                                                    multiple
                                                    onSelect={(e) => onSelect(e, index)} // Pass the index to the onSelect function
                                                    onError={onTemplateClear}
                                                    onClear={() => onTemplateClear(index)}
                                                    headerTemplate={(options) => headerTemplate(options, index)}
                                                    itemTemplate={(file, onRemove) => itemTemplate(file, onRemove, index)}
                                                    emptyTemplate={emptyTemplate}
                                                    chooseOptions={chooseOptions}
                                                    uploadOptions={uploadOptions}
                                                    cancelOptions={cancelOptions}
                                                    maxFileSize={42949672960}
                                                    value={val.uploadedFile}
                                                    className='uploadFiles'

                                                />
                                                {val.bool_msg_uploadedFile && <label style={{ color: 'red' }}>{val.message_uploadedFile}</label>}


                                            </div>

                                        </div>
                                        <div className='row' style={{ marginTop: '10px' }}>
                                            <MultiSelect style={{ width: "100%" }}
                                                panelStyle={{ fontWeight: 500 }}
                                                value={val.selected}
                                                name='manipulateur'
                                                emptyMessage='Aucun manipulateur disponible'
                                                onChange={(e) => handleMultiSelectChange(e.value, index)}
                                                options={membres} optionLabel="label"
                                                display="chip"
                                                placeholder="Sélectionner manipulateur * "
                                                filter
                                                maxSelectedLabels={3} className="w-full md:w-50rem manipu"
                                                itemTemplate={(option) => (
                                                    <div style={{ color: 'black', fontSize: "12px", fontWeight: 500 }}>{option.label}</div>
                                                )}
                                            />
                                            {val.bool_mani && <label style={{ color: 'red' }}>{val.message_mani}</label>}

                                        </div>
                                    </div>
                                    <div className='col col-md-3' style={{ padding: 15 }}>
                                        <div className='row'>
                                            <div className='col col-md-12'>

                                                {/* <AutoComplete
                                                    placeholder='Centre de soins'
                                                    className='w-full'
                                                    field="nomcentresoins"
                                                    suggestions={filteredCountries}
                                                    value={val.selectedCentreDeSoin}
                                                    completeMethod={search}
                                                    onChange={(e) => handleChangeSelectCentreSoin(e, index)}
                                                    readOnlyInput
                                                /> */}

                                                <Dropdown
                                                    style={{ width: "100%", marginTop: "-6px", fontWeight: 'bold', }}
                                                    panelStyle={{ fontWeight: 500 }}
                                                    value={val.selectedCentreDeSoin}
                                                    name='selectedCentreDeSoin'
                                                    emptyMessage='Aucun centre de soins disponible'
                                                    onChange={(e) => handleChangeSelectCentreSoin(e.value, index)}
                                                    options={centressoins}
                                                    optionLabel="societe"
                                                    optionValue='id'
                                                    // display="chip"
                                                    placeholder="Sélectionner centre de soins * "
                                                    filter

                                                    // maxSelectedLabels={3} className="w-full md:w-50rem"
                                                    itemTemplate={(option) => (
                                                        <div style={{ color: 'black', fontSize: "12px", fontWeight: 500 }}>{option.societe}</div>
                                                    )}
                                                />

                                                {val.bool_msg_selectedCentreDeSoin && <label style={{ color: 'red' }}>{val.message_selectedCentreDeSoin}</label>}
                                            </div>
                                        </div>
                                        <div className='row' style={{ height: "130px", aspectRatio: '16/9' }}>

                                            <div className={index === 0 ? 'col col-md-12' : 'col col-md-12'}>
                                                {val.imgSrc ? (
                                                    <img src={val.imgSrc}
                                                        style={{
                                                            cursor: 'pointer',
                                                            width: '100%',
                                                            height: '170px',
                                                            marginLeft: "25px"
                                                        }}
                                                        className='border-round'
                                                        alt="webcam"
                                                        name='imgSrc'
                                                        onClick={() => handleVisible(index)}
                                                        value={val.imgSrc} onChange={(e) => handleChangeImgSrc(e, index)}
                                                        capture="environment" /* Capture from device camera */
                                                        accept="image/*" /* Accept any image format */
                                                    // width="210mm" /* Set width to match A4 paper width */
                                                    // height="297mm" /* Set height to match A4 paper height */

                                                    />
                                                ) : (

                                                    <Webcam

                                                        ref={webcamRef}
                                                        mirrored={mirrored}
                                                        screenshotFormat="image/jpeg"
                                                        imageSmoothing={false}
                                                        screenshotQuality={0.9}
                                                        style={{
                                                            width: '100%',
                                                            height: "100%",
                                                            objectFit: 'cover',
                                                            marginLeft: "25px"
                                                        }}
                                                        className='rounded-sm'

                                                    />



                                                )}
                                                {val.bool_msg_imgSrc && <label style={{ color: 'red' }}>{val.message_imgSrc}</label>}
                                                <div className="btn-container" style={{ marginTop: '-10px' }}>
                                                    <div className="btn-container d-flex" style={{ marginTop: 15, display: 'flex', justifyContent: 'center' }}>
                                                        {/* <div >

                                                            {val.imgSrc ? (
                                                                <button type='button' onClick={(e) => retake(e, index)} className='btn btn-info' style={{ marginLeft: 5 }}>Retake photo</button>
                                                            ) : (
                                                                <button type='button' onClick={(e) => captureindex(index)} className='btn btn-warning' style={{ marginLeft: 5 }}>Capture photo</button>

                                                            )}

                                                        </div> */}
                                                        <Dialog header="Bulletin" visible={val.bool_large} style={{ width: '210mm', height: '100vh' }} onHide={() => handleVisibleCapuClose(index)}>
                                                            {val.imgSrc ? (
                                                                <img src={val.imgSrc} alt="webcam" name='imgSrc2'
                                                                    value={val.imgSrc} onChange={(e) => handleChangeImgSrc(e, index)}
                                                                    capture="environment"
                                                                    accept="image/*"
                                                                // style={{ width: '210mm', height: '297mm', border: '1px solid black' }}

                                                                />
                                                            ) : (
                                                                <Webcam

                                                                    ref={webcamRef}
                                                                    mirrored={mirrored}
                                                                    screenshotFormat="image/jpeg"
                                                                    imageSmoothing={true}
                                                                    screenshotQuality={0.9}
                                                                    style={{ width: '100%' }}

                                                                />

                                                            )}

                                                            {val.imgSrc ? (
                                                                <button type='button' onClick={(e) => retake(e, index)} className='btn btn-info' style={{ marginLeft: 5, marginTop: 30 }}>Retake photo</button>
                                                            ) : (
                                                                <button type='button' onClick={(e) => captureindex(index)} className='btn btn-warning' style={{ marginLeft: 5, marginTop: 30 }}>Capture photo</button>

                                                            )}
                                                        </Dialog>
                                                        <button type="button"
                                                            className='btn btn-danger absolute'
                                                            style={{ marginLeft: "10px" }}
                                                            onClick={() => handleVisibleCapu(index)}
                                                        ><i className='pi pi-camera'></i></button>
                                                        <button type="button"
                                                            className='btn btn-primary absolute'
                                                            style={{ marginLeft: "95px" }}
                                                            onClick={() => openFolderPickFileImage(index)}
                                                        ><i className='pi pi-paperclip'></i></button>




                                                        <input type='file'
                                                            ref={useRefInputImage}
                                                            style={{ marginLeft: 10, display: 'none' }}
                                                            name="filehelp"
                                                            onChange={(e) => handleChangeHelpFile(e, index)}
                                                            accept="image/*"
                                                        />

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <Dialog header={"Observation de " + (val.sexe === "Homme" ? "Mr " : "Mme ") + val.nom_patient} visible={val.bool_obs} onChange={(e) => handleChange(e, index)} style={{ width: '45vw' }} onHide={() => handleClose_obs(index)}>
                                        <textarea
                                            className='form-control'
                                            rows={10}
                                            name='observation'
                                            value={val.observation}
                                            onChange={(e) => handleChange(e, index)}
                                        >

                                        </textarea>
                                        <button type='button' className='btn btn-info mt' onClick={() => add_Observation(val.observation, index)}>Ajouter</button>
                                    </Dialog>
                                    <Dialog header={"Photo de " + (val.sexe === "Homme" ? "Mr " : "Mme ") + val.nom_patient} visible={val.bool} onChange={(e) => handleChange(e, index)} style={{ width: '55vw' }} onHide={() => handleClose(index)}>

                                        <TransformWrapper
                                            initialScale={1}
                                            initialPositionX={200}
                                            initialPositionY={100}
                                            style={{ width: '100%' }}
                                        >

                                            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                <React.Fragment >

                                                    <TransformComponent>
                                                        <div className='col col-md-12'>
                                                            <img src={val.imgSrc} style={{ cursor: 'pointer', width: '51vw', position: 'relative' }} alt="webcam" name='imgSrc' onClick={() => setVisible(true)}
                                                                value={val.imgSrc}
                                                            />
                                                        </div>
                                                    </TransformComponent>
                                                    <div className="tools" style={{ marginTop: 10 }}>
                                                        <button className='btn btn-info' onClick={() => zoomIn()}>
                                                            <i className='fa fa-plus'></i>
                                                        </button>
                                                        <button style={{ marginLeft: 5 }} onClick={() => zoomOut()} className='btn btn-info'>
                                                            <i className='fa fa-minus'></i>
                                                        </button>
                                                        <button className='btn btn-danger' style={{ marginLeft: 5 }} onClick={() => resetTransform()}>
                                                            <i className='fa fa-close'></i>
                                                        </button>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </TransformWrapper>


                                    </Dialog>
                                </div>
                            </div>
                        ))
                    }
                    <div className='container-fluid'>
                        <div className='row card' style={{ height: '50px' }}>
                            <div className='col col-md-10'>

                            </div>
                            <div className='col col-md-2'>
                                <button
                                    type='button'
                                    title='Envoyer le(s) dossier(s)'
                                    onClick={() => confirmCreateFolder()}
                                    className='btn btn-primary'
                                    style={{ float: 'right' }}
                                > <i class=" fa fa-upload"></i>
                                    &nbsp;&nbsp;Envoyer</button>
                            </div>
                        </div>
                    </div>

                    <br /> <br />

                </section>

            </section>}
            <br /> <br />
            <Footer />
        </div>
    );
}

export default CreateFolder;